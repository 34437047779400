@import url(https://fonts.googleapis.com/css2?family=Gotu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gotu&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Work Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.profile{
  display: none
}
.row section{
  margin-bottom:20px;
  margin-top:50px;
}
/* .title:hover + .profile{
  display:flex;
  position: fixed;
  top: 50%;
  bottom: 50%;
  z-index: -1;
  align-items: center;
    justify-content: center;
    opacity: 50%
} */
body {
  background:-webkit-gradient(linear,left top, left bottom,from(rgb(255 251 249)),color-stop(42%, rgb(255 253 249)),to(rgb(255 253 246)));
  background:linear-gradient(180deg,rgb(255 251 249),rgb(255 253 249) 42%,rgb(255 253 246))
}
.underline{
  margin-top: 125px;
  padding-bottom: 10px;
  border-bottom:rgb(62, 62, 62)solid 2px;
}
.underline h2{
  font-size: 36px;
}
.project-image {
  opacity: 1;
  display: block;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.project-image img {
  width: 250px;
  height: 250px;
}
.bodytext li {
  font-family: "Arial", sans-serif;
  padding-bottom: 15px;
  line-height: 150%;
  font-size: 1.06em;
  color: #2b372b;
}
h1,
h2,
h3 {
  -webkit-font-smoothing: subpixel-antialiased;
}
p {
  font-family: "Arial", sans-serif;
  font-size: 1.06em;
  line-height: 150%;
  color: #2b372b;
}

hr {
  border-color: rgb(200, 255, 173);
  background-color: rgb(255, 173, 176);
  border-style: solid;
  margin-top: 2%;
  padding-top: 0px;
  margin-bottom: 20px;
  text-decoration: none;
}
.left .row img{
  height: auto; 
  width: auto; 
  max-width: 300px; 
  max-height: 200px;
padding:10px;
}
h2 {
  margin-bottom: 7px;
}
/* Thumbnail Stuff */
.codecontent .project-title {
  margin-top: 20px;
}
.project-code {
  position: relative;
}
.content {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: left;
}
.codecontent {
  text-align: left;
  -webkit-transition: none;
  transition: none;
  opacity: 1;
}
.buttons {
  position: relative;
  left: 50%;
  top: -6.5%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 20px;
}
#gray{
  color: rgb(102, 133, 133)
}
.buttons a {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  background-color: rgb(255, 242, 242);
  color: rgb(204, 111, 111);
  font-size: 16px;
  padding: 12px;
  display: block;
}
.project:hover .buttons a {
  opacity: 1;
}
#DesignProjs {
justify-content: left;
max-width: 940px;
}
.project-title {
  color: rgba(63, 124, 110, 1);
}
.project-description {
  color: rgb(116 116 116);
  margin-top:4px;
}
#archivedesc {
  text-align: center;
  padding-left: 250px;
  padding-right: 250px;
}
.project:hover .project-image {
  color: #2d572d;
  opacity: 0.3;
}

.project:hover .content {
  opacity: 1;
}
.project-title {
  margin-top: 30px;
  font-size: 20px;
}

.footer {
  margin-top: 45px;
  margin-bottom: 15px;
}
.footer p {
  text-align: center;
  font-size: 12px;
  font-style: italic;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.footer a {
  font-size: 138%;
}
.nochange a {
  font-size: 100%;
}
.nochange{
  font-size:16px;
}
a {
  text-decoration: none;
  color: rgba(63, 124, 110, 1);
}
a:hover {
  color: #172117;
}
@-webkit-keyframes fade {
  from {
    color: rgba(63, 124, 110, 1);
  }
  to {
    color: #172117;
  }
}
@keyframes fade {
  from {
    color: rgba(63, 124, 110, 1);
  }
  to {
    color: #172117;
  }
}
.projmargins {
  display: block;
  max-width: 700px;
  margin: auto;
}
.aboutmargins {
  display: block;
  max-width: 900px;
  margin: auto;
}
.projmargins h2 {
  line-height: 150%;
  text-align: left;
  margin-top: 75px;
  margin-bottom: 0px;
}
.projmargins h3 {
  margin-top: 45px;
  margin-bottom: 10px;
  text-align: left;
}

.projmargins h4 {
  margin-bottom: 0px;
  margin-top: 75px;
  color: rgba(63, 124, 110, 1);
}
.projmargins img {
  display: block;
  margin: auto;
  width: 690px;
}
.special {
  text-align: center;
}
.special img {
  width: 250px;
  border-radius:40px;
  -webkit-box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.37);
}
.center1 {
  margin: auto;
}
h2.forty {
  margin-top: 40px;
}
h2.none {
  margin-top: 5px;
}
.forty h2 {
  margin-top: 40px;
}
.center {
  text-align: center;
}
.parent {
  display: flex;
  overflow: hidden;
  margin: 1em 0;
}
.col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin-right: 40px;
}
.four {
  display: flex;
}
.four .col {
  margin-right: 15px;
}
.four .col:nth-child(4) {
  margin-right: 0px;
}
.projheader p {
  font-size: 14px;
  margin-top: 0px;
}
.four h4 {
  margin-bottom: 10px;
}
.three {
  display: flex;
}
.three .col img {
  width: 250px;
  width: 100%;
  margin-top: 10px;
  border-radius:30px;
  -webkit-box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.37);
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  margin: auto;
}
/* halfthumb */
.halfproject .project-description {
  margin-top: 10px;
}
.halfproject .project-title {
  margin-top: 0px;
}
.halfproject .col {
  padding-left: 15px;
}
.halfproject {
  margin: 10px;
  max-width: 425px;
  padding: 10px;
  border: dotted rgb(152, 163, 161) 2px;
}
.halfproject img {
  width: 200px;
  height: 200px;
}
.halfproject .year {
  font-size: 14px;
  margin-top:4px;
}
.halfproject:hover {
  background-color: rgb(223, 233, 209);
  border: solid rgb(152, 163, 161) 2px;
}

#rnc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 920px;
  justify-content: center;
  margin: auto;
}
.opening img {
  -webkit-box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.37);
}

.col img {
  width: 80%;
  position: relative;
  margin-bottom: 15px;
}
.column img {
  position: relative;
  width: 70%;
}
.three p {
  width: 30%;
  float: left;
}
.three p:nth-child(2) {
  margin-left: 24px;
  margin-right: 24px;
}
.three:after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 50px;
}
.bodytext {
  padding-left: 8%;
  padding-right: 8%;
}
.projmargins .row {
  flex-wrap: nowrap;
}

.project {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  max-height: 350px;
}

.part1 img {
  margin-top: 35%;
  margin-bottom: 20px;
  margin-right: 40px;
  position: relative;
  display: inline-block;
  border-radius: 2%;
  box-shadow: 15px 15px rgb(255, 235, 183);
  width: 230px;
  height: auto;
}
.part2 {
  position: relative;
  padding-left: 3%;
  padding-right: 3%;
  width: 50%;
}
.part2 h3 {
  text-align: left;
}
.navigation {
  display: flex;
  justify-content: space-between;
  line-height: 3em;
  max-width: 920px;
  margin: auto;
  margin-top: 4vh;
  margin-bottom: 4vh;
}
.navigation h1 {
  font-family: "Gotu", sans-serif;
  text-align: left;
  font-size: 2em;
  padding: 0px;
  margin: 0px;
}
.navigation h2 {
  font-size: 20px;
  margin-top: 0px;
}
.navigation h2 .item {
  color: rgba(63, 124, 110, 1);
}
.navigation h2 a:hover {
  color: black;
  -webkit-animation: none;
          animation: none;
}
.item:hover {
  color: black;
  -webkit-animation: none;
          animation: none;
}
.navigation-sub {
  margin-left: auto;
}
.navigation-sub a {
  margin-left: 2vw;
  display: inline;
  font-size: 1em;
}
.header {
  text-align: left;
}
.item {
  color: black;
  font-size: 18px;
  background-repeat: no-repeat;
}
a.item {
  color: rgba(63, 124, 110, 1);
  font-size: 16px;
}
/* header styling */
#headerimage {
  margin-top: 25px;
  margin-bottom: 25px;
}
.projheader {
  width: 700px;
  margin: auto;
  margin-bottom: 40px;
}
.center1 img {
  margin-top: 20px;
  width: 1030px;
  display: block;
  margin: auto;
}
.projheader h1 {
  font-weight: 600;
  display: block;
  text-align: left;
  margin-bottom: 10px;
  margin-right: 30px;
  margin-top: 0px;
  padding: 0px;
  line-height: 1.2;
  font-size: 2em;
}
/* stickynav */
li.is-current {
  font-weight: 800;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky > nav {
  position: absolute;
  align-self: start;
  margin-top: 20px;
}
.section-nav {
  position: fixed;
  z-index: 1;
  right: 25px;
  overflow-x: hidden;
  padding-left: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 200px;
  width: 90%;
}
.section-nav a {
  font-size: 14px;
  color: rgb(113, 113, 113);
}
.section-nav a:hover {
  font-weight: 900;
  color: rgb(113, 113, 113);

}
.section-nav li {
  list-style-type: none;
  padding-bottom: 8px;
}
.section-nav ul {
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
}
@media only screen and (max-width: 1479px) {

}
@media only screen and (width: 2560px) {
  .projmargins{
    max-width: 900px
  }
  .projheader{
    width: 900px
  }
  .sticky > nav {
    margin-right: 300px;
}
.projmargins img{
  width: 890px;
}
}
@media only screen and (max-width: 1339px) {
  .section-nav {
    display: none;
  }
}
section {
  margin-top: 120px;
  margin-bottom: 120px;
}
/* .projheader em{
  font-size: 1.3em;
  color: #ff7083;
  margin-top: 0px;

} */

em {
  color: rgb(115 115 115);
}
.projheader h2 {
  margin-top: 5px;
  padding-top: 0px;
  margin-bottom: 5px;
  font-size: 1.25em;
  color: black;
}
span {
  font-size: 2em;
  margin-bottom: 5px;
  display: block;
  margin: auto;
  text-align: center;
}
.projheader h3 {
  font-size: 1em;
  color: rgba(63, 124, 110, 1);
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: "Gotu", sans-serif;
  text-rendering: optimizeLegibility;
  text-align: left;
}
.projheader .row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
}
/* .projheader .col{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
} */

h1 {
  font-family: "Gotu", sans-serif;
  font-weight: 600;
  display: inline-block;
  text-align: left;
  font-size: 45px;
}
h2 {
  font-size: 30px;
  font-family: "Gotu", sans-serif;
  font-weight: 400px;
}
h3 {
  font-family: "Gotu", sans-serif;
  font-size: 1.2em;
}
.dropdwn h3 {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}
.dropdwn button {
  outline: none;
  display: inline-block;
  border: none;
  background: white;
  font-size: 20px;
}
.listtext {
  display: inline-block;
  margin-right: 45px;
}
.listtext1 {
  display: flex;
  flex-direction: row;
}

/* bottom bar */
.projfooter {
  display: flex;
}
.projfooter h3 {
  font-weight: lighter;
  font-family: "Arial", sans-serif;
  margin-top: 0px;
  color: rgba(63, 124, 110, 1);
}
.right {
  display: block;
  text-align: right;
}
.right h2,
h3 {
  text-align: right;
}
.left {
  flex: 1 1;
}
.left h2 {
  text-align: left;
}
.left h3 {
  text-align: left;
}
#about {
  margin-top: 0px;
}
.aboutmargins ul {
  list-style: none;
}
/* this css file is bum booty */
.researchrow {
  display: flex;
  flex-direction: row;
}
.row .column2 img {
  width: 100%;
  height: 150px;
}
.column2 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin-right: 40px;
}
.column2 img {
  width: 300px;
  height: 300px;
}
#special h2{
  margin-top:4px;
}
@media (max-width: 900px) {
  .halfproject{
    max-width:200px;
  }
  .right h2, h3{
    margin-right: 4%;
  }
  .halfproject .col {
    padding: 0px;
  }
  .halfproject .project-title {
    margin-top: 5px;
  }
  #rnc {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 970px;
    justify-content: center;
    margin-left: 3%;
  }
  .part1 img {
    margin-top: 10%;
  }
  .three p:nth-child(2) {
    margin-left: 0px;
    margin-right: 0px;
  }
  .three p {
    width: 100%;
  }
  .projheader h1 {
    font-size: 32px;
  }
  .navigation-sub a:first-child {
    margin-left: 0;
  }
  .navigation {
    margin-left: 3%;
    display: block;
    max-width: 800px;
    /* padding-right: 10%;
    padding-left: 10%; */
  }
  #archivedesc {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
  }
  .part2 {
    width: 93vw;
  }
  hr {
    margin-top: 3%;
  }
  .projheader {
    width: 90vw;
  }
  .projmargins img {
    width: 90vw;
    margin-bottom: 15px;
  }
  .center1 img {
    width: 90vw;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .projmargins .row {
    flex-direction: column;
  }

  .projmargins {
    margin-left: 0%;
    margin-right: 0%;
  }
  .bodytext {
    padding-left: 3%;
    padding-right: 3%;
  }
  .projfooter h2 {
    font-size: 1.25em;
  }
  .projfooter h3 {
    font-size: 0.9em;
  }
  #DesignProjs{
    justify-content: center;
  }
}

.titledesc h3 {
  margin-top: 0px;
  font-family: "Gotu", sans-serif;
  text-align: left;
  font-size: 150%;
  width: 460px
}
.titledesc #andmore {
  text-align: right;
}
/* maybe delete */
.headerrow {
  display: flex;
  max-width: 1100px;
  margin: auto;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;

  /* background-image: linear-gradient(to bottom right, #fff6f5, #ffffff);  */
}
/* .headerrow img {
  width: 140px;
  height: 140px;
  margin-right: 2vw;
} */
.titledesc {
  margin: auto;
  justify-content: center;
  width: 920px;
  display: flex;
  flex-direction:row
}
@media (max-width: 900px) {
  .titledesc h3 {
    width: auto;

  }
  .titledesc {
    flex-direction:column;
    width: auto;
    margin-left:3%;
    margin-right:3%;
  }
}
.smalltitle{
  margin-left: 0px;
}
.smalltitle p {
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.02em;
  text-align: left;
  font-family: "Arial", sans-serif;
}
  .headerrow img {
    display: none;
  }
  .headerrow {
    margin: 0px;
    padding-left: 3%;
    padding-right: 3%;
  }


