@import url("https://fonts.googleapis.com/css2?family=Gotu&display=swap");

.titledesc h3 {
  margin-top: 0px;
  font-family: "Gotu", sans-serif;
  text-align: left;
  font-size: 150%;
  width: 460px
}
.titledesc #andmore {
  text-align: right;
}
/* maybe delete */
.headerrow {
  display: flex;
  max-width: 1100px;
  margin: auto;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;

  /* background-image: linear-gradient(to bottom right, #fff6f5, #ffffff);  */
}
/* .headerrow img {
  width: 140px;
  height: 140px;
  margin-right: 2vw;
} */
.titledesc {
  margin: auto;
  justify-content: center;
  width: 920px;
  display: flex;
  flex-direction:row
}
@media (max-width: 900px) {
  .titledesc h3 {
    width: auto;

  }
  .titledesc {
    flex-direction:column;
    width: auto;
    margin-left:3%;
    margin-right:3%;
  }
}
.smalltitle{
  margin-left: 0px;
}
.smalltitle p {
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.02em;
  text-align: left;
  font-family: "Arial", sans-serif;
}
  .headerrow img {
    display: none;
  }
  .headerrow {
    margin: 0px;
    padding-left: 3%;
    padding-right: 3%;
  }

